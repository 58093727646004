/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useContext} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { appContext } from "../contexts"

import Header from "./Header"
import Footer from "./Footer"
import FabCart from "./cart/FabCart"

import { Box } from "@mui/system"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from '@mui/material/styles';
import CookieNotice from "../components/CookieNotice"

import "./../styles/global.scss"

const Layout = ({ children, data, pageContext, location }) => {

  // console.log(data)
  // console.log(location)
  // console.log(pageContext)

  const {app} = useContext(appContext)

  const siteInfo = useStaticQuery(graphql`
    query siteInfo {
      site {
        siteMetadata {
          title
        }
      }
      years: allStrapiDialogiYear(sort: {fields: year, order: ASC}) {
        edges {
          node {
            year
          }
        }
      }
      aktualniDialogi: strapiDialogi(aktualna: {eq: true}) {
        leto {
          color
          year
          number
          textColor
        }
        title
        stevilka
      }
    }
  `)

  const years = siteInfo.years.edges.map(y => y.node.year)
  const dialogiCurrentYear = years[years.length - 1]

  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  return (
  <>
    <CookieNotice />
    <Box sx = { (theme) => ({
      transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      transform: (!lg && app.menu) ? `translateX(-${theme.mobileMenu.width})` : `translateX(0)`,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: theme.palette.grey1

    })}>
      <Header 
        data={data || pageContext}
        page={pageContext.page}
        pageContext={pageContext}
        lg={lg} 
        siteTitle={siteInfo.site.siteMetadata?.title || `Title`} 
        location={location}
        dialogiCurrentYear={dialogiCurrentYear}
        aktualniDialogi={siteInfo.aktualniDialogi}
        years={years}
      />
      <main style={{
        flexGrow: 1,
        // pointerEvent: app.menu ? 'none' : 'auto'
      }}>{children}</main>
      <Footer
        pageContext={pageContext}
        dialogiCurrentYear={dialogiCurrentYear}
      />
    </Box>
    {(!lg && location.pathname !== '/kosarica') && <FabCart />}
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
