import React, {useContext} from 'react'

import Fab  from '@mui/material/Fab'
import Badge from '@mui/material/Badge'
import Zoom from '@mui/material/Zoom'

import { CartIcon } from '../icons'
import { cartContext } from '../../contexts'

import { navigate } from 'gatsby'

export default function FabCart() {

  const {cart} = useContext(cartContext)

  return (
  <Zoom
    in={cart.length > 0}
    unmountOnExit
    timeout={300}
  >
    <Fab
      aria-label={'Dodaj v košarico'}
      sx = { (theme) => ({
        position: 'fixed',
        right: {
          xs: theme.spacing(4),
          sm: theme.spacing(8),
          lg: theme.spacing(10),
        },
        bottom: {
          xs: theme.spacing(4),
          sm: theme.spacing(8),
          lg: theme.spacing(10),
        },
        width: '3.5rem',
        height: '3.5rem',
        top: 'auto',
        backgroundColor: theme.palette.primary.main,
        fontSize: '1.75rem',
        transition: 'all 0.3 ease-in',
        '& svg path': {
          stroke: theme.palette.white
        },
        '&:hover': {
          backgroundColor: theme.palette.grey6,
        }
      })}
      onClick = {() => navigate('/kosarica')}
    >
      <Badge 
        badgeContent={cart.length} 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx = { (theme) => ({
        '& .MuiBadge-badge': {
          backgroundColor: theme.palette.white,
          bottom: '0.5em',
          right: '0.5em',
        }
      })}>
        <CartIcon sx = { (theme) => ({
          position: 'relative',
          top: `-0.1em`
        })} />
      </Badge>
    </Fab>
  </Zoom>
  )
};
