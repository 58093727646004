import React, {useContext} from 'react'

import Badge from '@mui/material/Badge'
import Zoom from '@mui/material/Zoom'
import IconButton from '@mui/material/IconButton'

import { CartIcon } from '../icons'
import { cartContext } from '../../contexts'

import { navigate } from 'gatsby'


export default function BtnCart() {

  const {cart} = useContext(cartContext)

  return (
  <Zoom
    in={cart.length > 0}
    unmountOnExit
    timeout={300}
  >
      <Badge 
        badgeContent={cart.length} 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}

        sx = { (theme) => ({
          '& .MuiBadge-badge': {
            backgroundColor: theme.palette.primary.main,
            bottom: '1.6em',
            right: '1.6em',
            color: theme.palette.white,
            p: 2,
            width: theme.spacing(5),
            height: theme.spacing(5),
            borderRadius: theme.spacing(5),
            pointerEvents: 'none'
          },
        })}
      >
        <IconButton onClick={()=> navigate('/kosarica')}
          sx = { (theme) => ({
            fontSize: '1.75rem',
            padding: theme.spacing(4),
          })}
        >
          <CartIcon sx = { (theme) => ({
            position: 'relative',
            top: '-0.1em'
          })} />
        </IconButton>
      </Badge>
  </Zoom>
  )
};
