import React from 'react';

import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { LazyLoadImage } from 'react-lazy-load-image-component';

import NagradaLabel from './NagradaLabel';

const Book = styled('div')(
  ({ theme }) => `
    transition: box-shadow 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    box-shadow:
      0.1px 0.2px 0.1px rgba(0, 0, 0, 0.009),
      0.2px 0.4px 0.2px rgba(0, 0, 0, 0.013),
      0.3px 0.7px 0.3px rgba(0, 0, 0, 0.016),
      0.5px 1px 0.5px rgba(0, 0, 0, 0.019),
      0.8px 1.5px 0.8px rgba(0, 0, 0, 0.021),
      1.1px 2.2px 1.1px rgba(0, 0, 0, 0.024),
      1.7px 3.3px 1.7px rgba(0, 0, 0, 0.027),
      2.7px 5.3px 2.7px rgba(0, 0, 0, 0.031),
      5px 10px 5px rgba(0, 0, 0, 0.04)
    ;

    ${theme.breakpoints.up("lg")} {
      box-shadow:
        0.4px 0.5px 0.2px -23px rgba(0, 0, 0, 0.032),
        0.8px 1.2px 0.5px -23px rgba(0, 0, 0, 0.047),
        1.3px 2px 0.8px -23px rgba(0, 0, 0, 0.057),
        2px 3px 1.2px -23px rgba(0, 0, 0, 0.067),
        2.9px 4.4px 1.8px -23px rgba(0, 0, 0, 0.075),
        4.1px 6.2px 2.5px -23px rgba(0, 0, 0, 0.083),
        5.8px 8.8px 3.5px -23px rgba(0, 0, 0, 0.093),
        8.4px 12.8px 5.1px -23px rgba(0, 0, 0, 0.103),
        12.9px 19.7px 7.9px -23px rgba(0, 0, 0, 0.118),
        23px 35px 14px -23px rgba(0, 0, 0, 0.15)
      ;

      .entering &, .entered & {
        box-shadow:
          0.2px 0.4px 0.2px -23px rgba(0, 0, 0, 0.032),
          0.5px 0.8px 0.5px -23px rgba(0, 0, 0, 0.047),
          0.9px 1.4px 0.8px -23px rgba(0, 0, 0, 0.057),
          1.3px 2.1px 1.2px -23px rgba(0, 0, 0, 0.067),
          1.9px 3px 1.8px -23px rgba(0, 0, 0, 0.075),
          2.7px 4.3px 2.5px -23px rgba(0, 0, 0, 0.083),
          3.8px 6px 3.5px -23px rgba(0, 0, 0, 0.093),
          5.5px 8.8px 5.1px -23px rgba(0, 0, 0, 0.103),
          8.4px 13.5px 7.9px -23px rgba(0, 0, 0, 0.118),
          15px 24px 14px -23px rgba(0, 0, 0, 0.15)
        ;

      }


    }

    &.shadow-s {
      box-shadow:
        0.2px 0.4px 0.2px -7px rgba(0, 0, 0, 0.011),
        0.4px 1px 0.4px -7px rgba(0, 0, 0, 0.016),
        0.7px 1.7px 0.7px -7px rgba(0, 0, 0, 0.02),
        1px 2.6px 1px -7px rgba(0, 0, 0, 0.023),
        1.5px 3.8px 1.5px -7px rgba(0, 0, 0, 0.027),
        2.2px 5.6px 2.2px -7px rgba(0, 0, 0, 0.03),
        3.3px 8.4px 3.3px -7px rgba(0, 0, 0, 0.034),
        5.3px 13.3px 5.3px -7px rgba(0, 0, 0, 0.039),
        10px 25px 10px -7px rgba(0, 0, 0, 0.05)
      ;

    }
    
    // zgib
    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      left: 5%;
      top: 0;
      background: rgba(0,0,0,0.05);
      box-shadow: 1px 0 2px rgb(255 255 255 / 10%);
    }
    // top senca
    &::before {
      z-index: 10;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 90%);
    }
`)

export const Cover = (props) => {

  const {img, alt, sx, gatsbyimageprops, external=false, className, onClick, nagrada } = props

  if (external){
    return (
    <Book className={className}>
      <LazyLoadImage
      alt={alt}
      height={img.height}
      src={img.src} // use normal <img> attributes as props
      width={img.width} 
      style={{
        display: 'block',
        width: '100%',
        height: 'auto'
      }}
      />
    </Book>
    )
  } else {
    const image = getImage(img)
    return (
      <Box sx={sx} className={className} onClick={onClick} {...props}>
        <GatsbyImage
          {...gatsbyimageprops}
          as={Book}  
          image={image}
          alt={alt || ''}
        />
        {nagrada && <NagradaLabel text={nagrada.text} type={nagrada.type} />}
      </Box>
    )
  }
};
