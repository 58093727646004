import React from 'react';
import * as styles from "./dialogiLabel.module.scss"
import Box from '@mui/system/Box';

export default function DialogiLabel({stevilka, year, number=false, color='#000000', textColor='#fff', sx}) {
  return (
    <Box component={'p'} className={styles.container} style={{
      display: 'flex',
      gap: '0.25em',
      alignItems: 'center',
    }} sx={sx}>
      {number && 
        <span className={styles.letnik} style={{
          backgroundColor: color,
          color: textColor
        }}>{number}</span>
      }
      <span className={styles.stevilka}>{stevilka.replace('-', '–')}</span>
      <span className={styles.leto}>{year}</span>
    </Box>
  )
};
