import React from "react"
import { Grid } from "@mui/material"
import Logo from "./Logo"
import SearchBtn from "./../search/SearchBtn"
import MenuBtn from "./MenuBtn"
import MobileMenu from "./MobileMenu"
import SearchModal from "../search"
import DesktopMenu from "./DesktopMenu"
import BtnCart from "../cart/BtnCart"
import DialogiNavigation from "../dialogi/DialogiNavigation"
import LangBtn from "./LangBtn"

const Header = ({siteTitle, lg, data, location, dialogiCurrentYear, years, page, aktualniDialogi, pageContext}) => {

  const dialogiPage = !!location.pathname.match(/^\/dialogi/)

  return (
    <>
      <Grid 
          container
          component="header" 
          sx={ theme => ({
            backgroundColor: theme.palette.grey1,
            justifyContent: 'space-between',
            alignItems: 'center',
            px: {
              sm: theme.spacing(4),
              lg: theme.spacing(8)
            },
            pt: {
              sm: theme.spacing(4)
            }
        })}>
          <Logo siteTitle={siteTitle} style={{marginRight: 'auto'}}/>
          {lg && <LangBtn lang={pageContext.langKey} />}
          {(lg && location.pathname !== '/kosarica') && <BtnCart />}
          <SearchBtn sx = { (theme) => ({
            fontSize: '1.75rem',
            padding: theme.spacing(4),
          })} />
          <SearchModal/>
          
          <MenuBtn />
          {lg ? 
            <DesktopMenu dialogiCurrentYear={dialogiCurrentYear} aktualniDialogi={aktualniDialogi} /> 
            : 
            <MobileMenu dialogiCurrentYear={dialogiCurrentYear} aktualniDialogi={aktualniDialogi} /> }
      </Grid>
      {dialogiPage && <DialogiNavigation dialogiCurrentYear={dialogiCurrentYear} pageContext={pageContext} location={location} years={years} page={page} />}
    </>
  )
}

export default Header
