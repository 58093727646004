import React, { useReducer, createContext, useEffect } from 'react';
import axios from 'axios';

import userReducer from '../reducers/user-reducer';
import { clearUser } from '../actions';

import { isBrowser } from '../../utility';

export const userContext = createContext()
const UserProvider = userContext.Provider

export function UserWrapper({children}){

  const localUser = isBrowser ?  window.localStorage.getItem('user') : false;

  const [user, dispatchUser] = useReducer(userReducer, localUser ? JSON.parse(localUser) : null )

  useEffect(()=> {
    // if user data are saved in local storage check if the token is still valid 
    if(user){
        console.log('checking ...')
        
        setTimeout(() => {
          axios.get(process.env.GATSBY_STRAPI_API_URL + '/api/users/me', {
            headers: {
              Authorization: `Bearer ${user.jwt}`
            }
          })
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.error(error)
            dispatchUser(clearUser())
          })
        }, 3000)
    }
  }, [user])

  return (
    <UserProvider value={{user, dispatchUser}}>
      {children}
    </UserProvider>
  )
}