import React, { createContext, useReducer } from 'react';
import cartReducer from '../reducers/cart-reducer';
import { isBrowser } from '../../utility';

export const cartContext = createContext();

const CartProvider = cartContext.Provider;

export function CartWrapper({ children }) {
  const localCart = isBrowser && JSON.parse( localStorage.getItem('cart') )
  const [cart, dispatchCart] = useReducer(cartReducer, localCart || [])

  return <CartProvider value={{ cart, dispatchCart }}>{ children }</CartProvider>
};
