import React from 'react';
import { Box, styled } from '@mui/system';
import Collapse from '@mui/material/Collapse';
import { Link } from 'gatsby';
import Button from '@mui/material/Button';
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import useLocalState from '../hooks/use-local-state';
import { isBrowser } from '../utility';
import Cookies from 'js-cookie';
import { StaticImage } from 'gatsby-plugin-image';

const Banner = styled(Box)(
  ({ theme }) => `
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    max-width: 400px;
`)

export default function CookieNotice() {
  
  const location = useLocation();
  if (isBrowser) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useLocalState(
    false,
    'cookieNoticeHidden',
  );

  const enableAnalytics = () => {
    Cookies.set('google-analytics', true)
    setBannerHidden(true);
  };
  
  return (
  <>
  {isBrowser && 
    <Banner>
      <Collapse in={!bannerHidden} mountOnEnter={true} unmountOnExit={true}>
          <Box  sx = { (theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
              flexWrap: 'wrap',
              m: 4,
              backgroundColor: theme.palette.grey4,
              fontFamily: 'var(--font-sans)',
              padding: theme.spacing(4),
              // borderRadius: theme.spacing(4),
              boxShadow:
                `0.5px 0.5px 1px -29px rgba(0, 0, 0, 0.054),
                1.2px 1.1px 2.3px -29px rgba(0, 0, 0, 0.078),
                2.2px 1.9px 4.2px -29px rgba(0, 0, 0, 0.097),
                3.7px 3.2px 6.9px -29px rgba(0, 0, 0, 0.113),
                6.1px 5.3px 11.4px -29px rgba(0, 0, 0, 0.132),
                10.6px 9.2px 19.9px -29px rgba(0, 0, 0, 0.156),
                23px 20px 43px -29px rgba(0, 0, 0, 0.21)`,

              '& p':{
                fontSize: {
                  xs: '0.9rem',
                  lg: '0.85rem'
                },
                textAlign: 'center',
                margin: 0
              }
              
            })}>
            <Box sx = { (theme) => ({
              mt: 2
            })}>
              <StaticImage 
                src="./../images/cookie-icon.svg" 
                alt="Slika obvestilo piškotkov"
                placeholder="none"
                width={100}
              />
            </Box>
            <p>To spletno mesto uporablja piškotke za analizo uporabe, s čimer zagotavljamo boljšo uporabniško izkušnjo.</p>
            <Box sx = { (theme) => ({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              my: 4
            })}>
              <Button sx = { (theme) => ({
                fontSize: '0.65rem'
              })} variant="contained" onClick={()=> enableAnalytics()}>Se strinjam</Button>
              <Button sx = { (theme) => ({
                fontSize: '0.65rem'
              })} component={Link} variant="underlined" disableRipple={true} to='/pravno-obvestilo'>Več o piškotkih</Button>
            </Box>
          </Box>
      </Collapse>
    </Banner>
  }
  </>
  )
};
