export const SET_USER = "SET_USER"
export const CLEAR_USER = "CLEAR_USER"

export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const CLEAR_CART = "CLEAR_CART"
export const REMOVE_COMPLETELY = "REMOVE_COMPLETELY"

export const OPEN_MENU = "OPEN_MENU"
export const CLOSE_MENU = "CLOSE_MENU"

export const OPEN_SEARCH = "OPEN_SEARCH"
export const CLOSE_SEARCH = "CLOSE_SEARCH"

export const SET_DIALOGI_YEAR = "SET_DIALOGI_YEAR"