import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import { SearchIcon } from './SearchBtn';
import CloseBtn from './../Header/CloseBtn';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';


const SearchInput = styled(InputBase)(({ theme }) => (`
  font-family: --var(sans-font);
  display: flex;
  color: ${theme.palette.primary.main};
  font-weight: bold;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.3s ease-in-out;
  border-bottom: 3px solid ${theme.palette.primary.main};
  width: 100%;
  margin: 0 auto;
  font-size: var(--fs-l);
  font-weight: 700;
  
  & .MuiInputBase-input {
    line-height: 1.8;
    height: auto;
    padding:0
  }

  & .MuiInputBase-input::placeholder {
    color: ${theme.palette.grey5};
    font-weight: 700
  }
`));


// const StyledInputRoot = styled('div')(
//   ({ theme }) => `
  
//   display: flex;
//   font-weight: 500;
//   border-bottom: 2px solid ${theme.palette.primary.main};
//   align-items: center;
//   justify-content: center;
//   // background-color: ${theme.palette.grey1};

//   &.${inputUnstyledClasses.focused} {
//     border-bottom: 3px solid ${theme.palette.primary.main};
//   }
//   &:hover {
//     border-bottom: 3px solid ${theme.palette.primary.main};
//   }
// `,
// );

// const StyledInputElement = styled('input')(
//   ({ theme }) => `
//   font-size: 1em;
//   font-family: inherit;
//   font-weight: 400;
//   line-height: 1.5;
//   flex-grow: 1;
//   color: ${theme.palette.primary.main};
//   background: inherit;
//   border: none;
//   border-radius: inherit;
//   padding: 0;
//   outline: 0;
//   display: block;
//   width:100%;

//   &::placeholder {
//     color: ${theme.palette.grey4};
//     font-weight: 500
//   }
// `,
// );

const InputAdornment = styled('div')`
  //margin: 0.125em 0.25em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
`;

export default function SearchField({sx, onChange, loading, value, onClose}) {
  return (
    <Box sx={sx}>
      <SearchInput
        style={{fontSize: '1em'}}
        placeholder={'iskalni niz ...'}
        autoFocus
        startAdornment={
          <InputAdornment>
            <SearchIcon sx = { (theme) => ({
              margin: '8px',
              marginRight: '0.25em'
            })}/>
          </InputAdornment>}
        endAdornment={
          <InputAdornment sx={{mr:0}}>
            {loading &&  <CircularProgress size={'1em'} sx={{m: 'auto', display: 'block', margin:'0.25em'}}/> }
            <CloseBtn 
              sx={{
                fontSize: {
                  xs: '1em',
                  lg: '0.75em'
                },
                padding:0
              }} 
              onClick={onClose} 
            />
          </InputAdornment>}
        onChange={ (e) => onChange(e.target.value)}
        value={value}
      />
      <Typography sx = { (theme) => ({
          position: 'absolute',
          right: 0,
          mt: theme.spacing(2),
          textAlign: 'right',
          fontSize: '0.5em',
          fontWeight: 500

        })} variant='p'>Vpišite naslov knjige, avtorja ali ključno besedo</Typography>
    </Box>
  );
}