import React from 'react';
import { SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export default function ContactLinks(props) {


  const Svg = styled(SvgIcon)(
    ({ theme }) => `
      width: ${theme.spacing(10)};
      height: ${theme.spacing(10)};
      & path {
        fill: ${theme.palette.black};
        // fill: ${theme.palette.white};
      }
    `,
  );

  return (
    <Grid container alignItems={'center'} {...props}>
      <Grid item ml={-1}>
        <Svg>
          <path d="M5.3 16.8c-.4.3-.9.1-1.1-.3V16.2l.1-1.6h-.5c-.9 0-1.7-.6-1.9-1.5V5.5c0-1 .7-1.8 1.6-1.9h11.1c1 0 1.8.7 1.9 1.6v.4l-.1 2.8h4c.9 0 1.7.6 1.9 1.5V17.6c0 1-.7 1.8-1.6 1.9h-.4l-.4-.1v1.7c0 .5-.4.8-.9.7h-.2l-.1-.1-3.3-2.2H9.7c-1 0-1.8-.7-1.9-1.6v-.4l.1-1.4v.7-1.5l-2.6 1.5zm15.1-7.2h-4.1v3c0 .9-.7 1.7-1.6 1.8H9.1v3c0 .3.2.5.5.6h6.1c.1 0 .2 0 .3.1h.1l2.6 1.8v-1.3c0-.3.2-.5.5-.6h1.3c.3 0 .5-.2.6-.5V10.3c-.2-.4-.4-.7-.7-.7zm-5.9-4.7H3.7c-.3 0-.5.2-.6.5V12.6c0 .3.2.5.5.6h1.3c.3 0 .5.2.6.5V15l2.6-1.8c.1 0 .2-.1.2-.1h6.1c.3 0 .5-.2.6-.5V5.5c0-.3-.2-.6-.5-.6z"/>
        </Svg>
      </Grid>
      <Grid item ml={2} sx = { (theme) => ({
        '& a': {
          fontSize: '1.25rem',
          fontWeight: 400,
          lineHeight: '110%'
        }
      })}>
          <a href="tel:0038622502193"> +386 2 250 21 93</a><br/>
          <a href="mailto:info@aristej.si">info@aristej.si</a>
      </Grid>
    </Grid>
    
  )
};
