import React from 'react';

import { SvgIcon, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Svg = styled(SvgIcon)(
  ({ theme }) => `
    width: 1em;
    height: 1em;
    font-size: 1em;
    margin:0.25em;
    & path, & circle {
      stroke: ${theme.palette.primary.main};
      stroke-width: 2px;
      stroke-linecap: round;
      fill: none;
    }
  `,
);
export const CloseIcon = (props) => (
  <Svg {...props}>
    <path d="m2 1.9 20.2 20.2M22.1 1.9 2 22.1"/>
  </Svg>
)
export default function CloseBtn({sx, onClick}) {
  return (
    <IconButton sx={sx} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  )
};
