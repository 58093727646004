/**
 * Main wrapper for gatsby-browser.js and gatsby-ssr.js
 */
import React from 'react';
// import { ApolloWrapper } from './src/apollo/ApolloWrapper'
import { 
  CartWrapper,
  AppWrapper
} from './src/contexts'

export default function RootWrapper({ element }) {
  return (
    <AppWrapper>
      <CartWrapper>
        {element}
      </CartWrapper>
    </AppWrapper>
  )
}
