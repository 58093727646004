import React, {useContext} from 'react';
import { Link } from 'gatsby';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';

import Modal from './MenuModal';
import { Container } from '../../grid';

import { appContext } from '../../contexts';
import { closeMenu } from '../../contexts/actions';
import { Typography } from '@mui/material';

import DialogiLabel from './../dialogi/DialogiLabel'

const NavigationContainer = styled(Container)(
  ({ theme }) => `
  font-size: 1rem;
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  & h2 {
    font-size: 2.5em;
    font-family: var(--font-serif);
  }
  & h2:first-of-type {
    margin-bottom: 0.5em
  }
  & li>a {
    font-weight: 300;
    display: block;
  }
  & ul li ul li > a {
    padding: 0.5em 0;
    font-size: 1.5em
  }
`,
);

export default function DesktopMenu({dialogiCurrentYear, aktualniDialogi}) {

  const {app, setApp} = useContext(appContext)
  const clickHandler = () => setApp(closeMenu())

  return (
    <Modal>
      <NavigationContainer component={'nav'}>
        <Box component={'ul'} sx = { (theme) => ({
          display: 'grid',
          gap: theme.spacing(13),
          gridTemplateColumns: '1fr 1fr',
          justifyContent: 'center',
          justifyItems: 'center',
          '&>div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(2),
            
          }
        })}>
          <Box>
            <li>
              <h2>Knjige</h2>
              <ul>
                <li><Link className='underlined-link' onClick={clickHandler} to='/humanistika-in-druzboslovje'>Humanistika in družboslovje</Link></li>
                <li><Link className='underlined-link' onClick={clickHandler} to='/poljudna-znanost-prirocniki'>Poljudna znanost, priročniki</Link></li>
                <li><Link className='underlined-link' onClick={clickHandler} to='/leposlovje-za-otroke'>Leposlovje za otroke</Link></li>
              </ul>
            </li>
            <li>
              <h2>
                <Link className='underlined-link' onClick={clickHandler} to='/kamisibaji'>Kamišibaji</Link>
              </h2>
            </li>
            <li>
              <h2>
                <Link className='underlined-link' onClick={clickHandler} to='/o-zalozbi'>O založbi</Link>
              </h2>
            </li>
          </Box>
          <Box>
            <li>
              <h2>Dialogi</h2>
              <ul>
                <li style={{marginBottom: '1rem'}}>
                  <Link to={`/dialogi/${aktualniDialogi.leto.year}/${aktualniDialogi.stevilka}`} onClick={clickHandler}>
                    <Box sx = { (theme) => ({
                      display: 'flex',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: theme.spacing(4),
                    })}>
                      <Typography component={'h3'} variant={'bookAuthor'}>Zadnja številka</Typography>
                      <DialogiLabel 
                        stevilka={aktualniDialogi.stevilka} 
                        year={aktualniDialogi.leto.year} 
                        number={aktualniDialogi.leto.number} 
                        color={aktualniDialogi.leto.color}
                        textColor={aktualniDialogi.leto.textColor}
                        sx = { (theme) => ({
                          m:0,
                          fontSize: '1.5rem'
                        })}
                      />
                    </Box>
                    <Typography variant={'bookTitle'}>{aktualniDialogi.title}</Typography>
                  </Link>
                </li>
                <li>
                  <Link className='underlined-link' onClick={clickHandler} to={`/dialogi/${app.dialogiYear ? app.dialogiYear : dialogiCurrentYear}`}>Posamezne številke in arhiv</Link>
                </li>
                <li>
                  <Link className='underlined-link' onClick={clickHandler} to='/dialogi/o-dialogih'>O dialogih</Link>
                </li>
                <li>
                  <Link className='underlined-link' onClick={clickHandler} to='/dialogi/navodila-za-avtorje'>Navodila za avtorje</Link>
                </li>
                <li>
                  <Link className='underlined-link' onClick={clickHandler} to='/dialogi/postani-narocnik'>Postani naročnik</Link>
                </li>
                
              </ul>
            </li>
          </Box>
        </Box>
      </NavigationContainer>
    </Modal>
  )
};
