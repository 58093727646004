
import { 
  SET_USER,
  CLEAR_USER,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  REMOVE_COMPLETELY,
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_SEARCH,
  CLOSE_SEARCH,
  SET_DIALOGI_YEAR
} from "./action-types";

/**
 * Action Creators – return action (object wti property type and payload)
 */

/** USER ACTIONS */

export const setUser = user => {
  return {
    type: SET_USER,
    payload: { user }
  }
}
export const clearUser = () => {
  return {
    type: CLEAR_USER,
    payload: { user: null }
  }
}


/** CART ACTIONS */

export const addToCart = (item, qty) => ({
  type: ADD_TO_CART,
  payload: {
    item,
    qty
  }
})

export const removeFromCart = (item, qty) => ({
  type: REMOVE_FROM_CART,
  payload: {
    qty,
    item
  }
})

export const removeAll = () => ({
  type: CLEAR_CART
})

export const removeCompletely = (item) => ({
  type: REMOVE_COMPLETELY,
  payload: {
    item
  }
})


/**
 * MENU ACTIONS
 */
export const openMenu = () => ({
  type: OPEN_MENU
})
export const closeMenu = () => ({
  type: CLOSE_MENU
})

/**
 * SEARCH ACTIONS
 */
 export const openSearch = () => ({
  type: OPEN_SEARCH
})
export const closeSearch = () => ({
  type: CLOSE_SEARCH
})


/**
 * DIALOGI ARCHIVE
 */
 export const setYear = (year) => ({
  type: SET_DIALOGI_YEAR,
  payload: year
})
