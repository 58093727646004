import React, {useState, useContext} from 'react';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { appContext } from '../../contexts';
import { closeMenu, openMenu } from '../../contexts/actions';

import { Link } from 'gatsby';


const menuContent = (dialogiCurrentYear, app, aktualniDialogi) =>[
  {
    type: 'multiple',
    label: 'Knjige',
    items: [
      {
        label: 'Humanistika in družboslovje',
        to: '/humanistika-in-druzboslovje'
      },
      {
        label: 'Poljudna znanost, priročniki',
        to: '/poljudna-znanost-prirocniki'
      },
      {
        label: 'Leposlovje za otroke',
        to: '/leposlovje-za-otroke'
      },
    ]
  },
  {
    type: 'multiple',
    label: 'Dialogi',
    items: [
      {
        label: 'Zadnja številka',
        to: `/dialogi/${aktualniDialogi.leto.year}/${aktualniDialogi.stevilka}`
      },
      {
        label: 'Posamezne številke in arhiv',
        to: `/dialogi/${app.dialogiYear ? app.dialogiYear : dialogiCurrentYear}`
      },
      {
        label: 'O dialogih',
        to: '/dialogi/o-dialogih'
      },
      {
        label: 'Navodila za avtorje',
        to: '/dialogi/navodila-za-avtorje'
      },
      {
        label: 'Postani naročnik',
        to: '/dialogi/postani-narocnik'
      },
    ]
  },
  {
    type: 'single',
    label: 'Kamišibaji',
    to: '/kamisibaji'
  },
  {
    type: 'single',
    label: 'O založbi',
    to: '/o-zalozbi'
  },
  {
    type: 'single',
    label: 'English',
    to: '/en'
  }
]

const menuItemStyle = {
  fontWeight: 500,
  fontSize: '1.2rem',
  margin: 0
}

const CollapsableListItem = ({label, subItems, menuItemClickHandle}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton onClick={()=>setOpen(!open)}>
        <ListItemText primaryTypographyProps={{sx: menuItemStyle}} primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit >
        <List component="div" disablePadding>
          {subItems.map(({label, to}) => (
            <Link to={to} onClick={menuItemClickHandle} key={label}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={label} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
    
  )
}

export default function MobileMenu({children, dialogiCurrentYear, aktualniDialogi}) {
  
  const {app, setApp} = useContext(appContext)

  const menuItemClickHandle = () => {
    setTimeout(() => setApp(closeMenu()), 250)
  }

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={app.menu}
      elevation={0}
      onClose={() => setApp(closeMenu())}
      onOpen={() => setApp(openMenu())}
      sx = { (theme) => ({
        '& .MuiDrawer-paper': {
          width: '300px',
          backgroundColor: theme.palette.grey2
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent'
        }
      })}
    >
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {menuContent(dialogiCurrentYear, app, aktualniDialogi).map((item) => (
        (item.type === 'single') ? (
          <Link to={`${item.to}`} onClick={()=>setTimeout(() => setApp(closeMenu()), 250)} key={item.label}>
            <ListItemButton>
              <ListItemText primaryTypographyProps={{sx: menuItemStyle}} primary={item.label} />  
            </ListItemButton>
          </Link>
        ) : (
          <CollapsableListItem key={item.label} label={item.label} subItems={item.items} menuItemClickHandle={menuItemClickHandle}/>
        )
      ))}
    </List>   
  </SwipeableDrawer>
  )
};
