import { 
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  REMOVE_COMPLETELY
 } from "./../actions/action-types";

const saveData = ( cart ) => {
  localStorage.setItem('cart', JSON.stringify(cart))
}

export default function cartReducer( state, action ) {
  let updatedCart = [...state]
  let existingItem

  // Check if the item already exist in the cart 
  if (action.payload) {
    existingItem = state.findIndex( item => item.id === action.payload.item.id )
  }

  switch (action.type) {
    case ADD_TO_CART:
      if (existingItem !== -1) {
        updatedCart[existingItem].qty += action.payload.qty
      } else {
        updatedCart.push({
          ...action.payload.item,
          qty: action.payload.qty
        })
      }
      saveData(updatedCart)
      return updatedCart
    case REMOVE_FROM_CART:
      if (existingItem !== -1) {
        const newQty = updatedCart[existingItem].qty - action.payload.qty
        if ( newQty > 0 ){
          // set to new quantity
          updatedCart[existingItem] = {
            ...updatedCart[existingItem],
            qty: newQty
          }
        } else {
          // remove item from cart
          updatedCart = updatedCart.filter( item => item.id !== action.payload.item.id)
        }
      }
      saveData(updatedCart)
      return updatedCart
    case REMOVE_COMPLETELY:
      updatedCart = updatedCart.filter( item => item.id !== action.payload.item.id)
      saveData(updatedCart)
      return updatedCart
    case CLEAR_CART:
      localStorage.removeItem('cart')
      return []
    default:
      return state
  }


};
