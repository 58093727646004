import React, {forwardRef, useEffect, useRef} from 'react';
import * as styles from './../../styles/modal.module.scss'
import { Transition } from 'react-transition-group';
import {easing as ease} from '../../utility/easings'
import PropTypes from 'prop-types';


export const SearchTransition = forwardRef(function SearchTransition(props, ref) {
  const { in: isOpened, children, onEnter, onExited, ...other } = props;
  const path = useRef(null);

  const duration = 700
  const numPoints = 2
  const delayPointsArray = [];
  const delayPointsMax = 0;

  let timeStart = Date.now();
  let isAnimating = false;

  const updatePath = (time) => {
    const points = [];
    for (let i = 0; i < numPoints; i++) {
      const thisEase = isOpened ? 
        (i === 1) ? ease.cubicOut : ease.cubicInOut:
        (i === 1) ? ease.cubicInOut : ease.cubicOut;
      points[i] = thisEase(Math.min(Math.max(time - delayPointsArray[i], 0) / duration, 1)) * 100
      //points[i] = thisEase(Math.min(time / this.duration, 1)) * 100
    }
    //console.log(isOpened, points)

    let str = '';
    str += (isOpened) ? `M 0 0 V ${points[0]} ` : `M 0 ${points[0]} `;
    for (let i = 0; i < numPoints - 1; i++) {
      const p = (i + 1) / (numPoints - 1) * 100;
      const cp = p - (1 / (numPoints - 1) * 100) / 2;
      str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${points[i + 1]} `;
    }
    str += (isOpened) ? `V 0 H 0` : `V 100 H 0`;

    return str;
  }

  const render = () => path.current.setAttribute('d', updatePath(Date.now() - timeStart))
  
  const renderLoop = () => {
    render();
    console.log('render')
    if (Date.now() - timeStart < duration + delayPointsMax) {
      requestAnimationFrame(() => {
        renderLoop();
      });
    }
    else {
      if (!isOpened) {
        onExited()
      }
    }
  }

  useEffect(()=> {
    if (isOpened){
      onEnter()
    }
    console.log('Component updating ...')
    isAnimating = true;
    for (var i = 0; i < numPoints; i++) {
      delayPointsArray[i] = 0;
    }
    timeStart = Date.now();
    renderLoop();
  }, [isOpened])

  return (
    <div ref={ref} {...other}>
      <Transition in={isOpened} timeout={duration} appear={true} mountOnEnter>
        {state => (
          <div className={`${styles.container} ${isOpened ? 'search-open' : 'search-close'} ${state}-modal`}>
          {console.log(state)}
            <svg className={styles.overlay} viewBox="0 0 100 100" preserveAspectRatio="none">
              <path ref={path} className={styles.path}></path>
            </svg>
            <div className={styles.content}>
              {children}
            </div>
          </div>
        )}
      </Transition>
    </div>
    
  )
});

SearchTransition.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};