import React, {useContext} from 'react';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import { appContext } from '../../contexts';
import { openMenu, closeMenu } from '../../contexts/actions';

import {Twirl as Hamburger} from './hamburger/src/TwirlFries';

export default function MenuBtn() {
  const {app, setApp} = useContext(appContext) 
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('xxxl'));

  return (
    <IconButton sx = { (theme) => ({
      p: {xs: 2, sm: 2, xxxl: 3},
      ml: {xs: 0, lg: 3},
      position: 'relative',
      zIndex: 300,
      backgroundColor: {
        md2: app.menu ? theme.palette.grey1 : theme.palette.grey2
      }
    })}
    onClick = {(e) => {
      console.log('click')
      setApp(app.menu ? closeMenu() : openMenu())
      // setOpen(open ? false : true)
    }}
    >
      <Hamburger 
        toggled={app.menu} 
        toggle={(e) => setApp(e ? openMenu() : closeMenu())} 
        size={matches ? 38: 30}
        color={theme.palette.primary.main}
        label="Odpri meni"
        rounded
        easing={theme.transitions.easing.easeOutBack}
        duration={0.4}
        direction={'left'}
        distance={matches ? 'lg' : 'md'}
      />
    </IconButton>
  )
};
