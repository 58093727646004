import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import ContactLinks from "./ContactLinks";
import FbBtn from './FbBtn';
import { Container, GridContainer } from '../../grid';
import { StaticImage } from 'gatsby-plugin-image';
import { styled } from '@mui/material';

const SmallText = styled('small')(
  ({ theme }) => `
    font-size: 0.7rem;
    display: block;
    line-height: 1.35;

    & a {
      font-weight: bold
    }
`)

export const FooterEn = ({dialogiCurrentYear}) => {
  
  return (
  <Container py={{xs: 7}}>
    <GridContainer>
      <Grid item xs={12} md={4}>
        <h2>Contact us</h2>
        <p>Založba Aristej d.o.o.<br/>
          Marčičeva ulica 19<br/>
          SI-2000 Maribor<br/>
          Slovenia
        </p>
        <p>Post address:<br/>Založba Aristej, P.O.B.. 115, SI-2116 Maribor, Slovenia</p>
       
      </Grid>
      <Grid item xs={12} md={4}>
        <ContactLinks mt={7} />
        <Box mt={7} ml={-2}>
          <FbBtn href='https://www.facebook.com/zalozba.aristej/'>Založba Aristej</FbBtn>
          <FbBtn href='https://www.facebook.com/revijadialogi/'>Revija Dialogi</FbBtn>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box sx = { (theme) => ({
          mt: 8,
          display: 'flex',
          gap: 4,
          alignItems: 'flex-end',
          '&>div': {
            flexShrink:0
          }
        })}>
          <StaticImage placeholder='none' alt="Logo Javne agencije za knjigo RS" width={100} src='./../../images/jak_logo.svg' />
          <SmallText>Prenovo spletne strani v letu 2022 je omogočila Javna agencija za knjigo RS.</SmallText>
        </Box>
        <Box sx = { (theme) => ({
          mt: 8,
          display: 'flex',
          gap: 3,
          alignItems: 'center',
          '&>div': {
            flexShrink:0
          }
        })}>
          <StaticImage placeholder='none' alt="Logo Primož Weingerl" width={35} src='./../../images/logo-pw.svg' />
          <SmallText>Design and development<br/> <a href="https://weingerl.com">weingerl.com</a> </SmallText>
        </Box>
      </Grid>
    </GridContainer>
  </Container>
  )
};
