import React from 'react';
import { SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSvg = styled(SvgIcon)(
  ({ theme }) => `
    width: 1em;
    height: 1em;
    font-size: 1em;
    line-height: 1;
    display: block;

    & path, & circle {
      stroke: ${theme.palette.primary.main};
      stroke-width: 1.5px;
      stroke-linecap: round;
      fill: none;
      stroke-miterlimit:10
    }
  `,
);

export const MoreIcon = (params) => {
  return (
    <StyledSvg {...params}>
      <path d="m12.8 7.7 4.7 4.3-4.7 4.3" style={{strokeLinejoin:'round'}}/><path d="M6.5 12h10.2"/><path d="M12 1.1C18 1.1 22.9 6 22.9 12S18 22.9 12 22.9 1.1 18 1.1 12 6 1.1 12 1.1z"/>
    </StyledSvg>
  )
};

export const CartIcon = (params) => {
  return (
    <StyledSvg {...params}>
      <g><path d="m20.9 23-17.8-.1c-.9 0-1.6-.6-1.6-1.5l.9-14.1c0-.8.7-1.5 1.6-1.5h15.9c.9 0 1.6.6 1.6 1.5l1 14.2c-.1.9-.8 1.5-1.6 1.5z"/><path d="M17.2 9.9v-4C17.2 3.2 15 1 12.3 1s-5 2.2-5 4.9v4"/></g>
    </StyledSvg>
  )
};


export const PersonIcon = (params) => {
  return (
    <SvgIcon {...params}>
      <path d="M12 11.4q-1.2 0-2.062-.863-.863-.862-.863-2.062 0-1.2.863-2.063Q10.8 5.55 12 5.55q1.2 0 2.062.862.863.863.863 2.063 0 1.2-.863 2.062Q13.2 11.4 12 11.4Zm-6.85 7.025V16.85q0-.6.338-1.112.337-.513.937-.813 1.425-.65 2.813-1 1.387-.35 2.762-.35t2.763.35q1.387.35 2.812 1 .6.3.938.813.337.512.337 1.112v1.575Zm.9-.925h11.9v-.625q0-.35-.225-.638-.225-.287-.6-.512-1.225-.6-2.512-.913Q13.325 14.5 12 14.5t-2.613.312q-1.287.313-2.512.913-.375.225-.6.512-.225.288-.225.613ZM12 10.475q.825 0 1.413-.588Q14 9.3 14 8.475t-.587-1.413q-.588-.587-1.413-.587-.825 0-1.412.587Q10 7.65 10 8.475q0 .825.588 1.412.587.588 1.412.588Zm0-2Zm0 9.025Z"/>
    </SvgIcon>
  )
};

export const CompanyIcon = (params) => {
  return (
    <SvgIcon {...params}>
      <path d="M3.85 19.725V7.25H7.7V3.425h8.625V11.1h3.825v8.625h-6.675v-3.85H10.55v3.85Zm.925-.925H7.7v-2.925H4.775Zm0-3.85H7.7v-2.925H4.775Zm0-3.85H7.7V8.175H4.775Zm3.85 3.85h2.925v-2.925H8.625Zm0-3.85h2.925V8.175H8.625Zm0-3.85h2.925v-2.9H8.625Zm3.85 7.7H15.4v-2.925h-2.925Zm0-3.85H15.4V8.175h-2.925Zm0-3.85H15.4v-2.9h-2.925Zm3.85 11.55h2.925v-2.925h-2.925Zm0-3.85h2.925v-2.925h-2.925Z"/>
    </SvgIcon>
  )
};

export const PaymentDeliveryIcon = (params) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...params}>
      <path d="M29.92 33.8h-9.67c-.4 0-.72.32-.72.72 0 .4.32.72.72.72h9.67c.4 0 .72-.32.72-.72 0-.39-.32-.72-.72-.72zM4.41 18.2c.4 0 .72-.32.72-.72v-5.54c0-.82.67-1.48 1.48-1.48h23.31c.82 0 1.48.67 1.48 1.48V27.6c0 .4.32.72.72.72.4 0 .72-.32.72-.72V11.93c0-1.62-1.31-2.93-2.93-2.93H6.61C5 9 3.68 10.31 3.68 11.93v5.54c0 .4.33.73.73.73z"/><path d="M12.42 20.56c0-.4-.32-.72-.72-.72H2.74c-.4 0-.72.32-.72.72 0 .4.32.72.72.72h8.96c.4 0 .72-.32.72-.72zM11.7 22.96H5.03c-.4 0-.72.32-.72.72 0 .4.32.72.72.72h6.67c.4 0 .72-.32.72-.72 0-.39-.32-.72-.72-.72zM11.7 26.09H6.28c-.4 0-.72.32-.72.72s.32.72.72.72h5.42c.4 0 .72-.32.72-.72s-.32-.72-.72-.72zM13.68 29.01c-2.69 0-4.88 2.13-4.99 4.79H6.61c-.82 0-1.48-.67-1.48-1.48v-3.33c0-.4-.32-.72-.72-.72-.4 0-.72.32-.72.72v3.33c0 1.62 1.31 2.93 2.93 2.93h2.24C9.4 37.4 11.36 39 13.68 39c2.75 0 5-2.24 5-5 0-2.75-2.25-4.99-5-4.99zm0 8.54a3.54 3.54 0 0 1-3.49-3v-.02c0-.02-.01-.05-.01-.07-.02-.15-.05-.3-.05-.45a3.55 3.55 0 0 1 7.1 0c0 1.95-1.59 3.54-3.55 3.54zM35.77 29.01c-2.75 0-5 2.24-5 5 0 2.75 2.24 5 5 5 2.75 0 5-2.24 5-5s-2.24-5-5-5zm0 8.54a3.55 3.55 0 1 1 .002-7.102 3.55 3.55 0 0 1-.002 7.102z"/><path d="M13.58 33.07a1.04 1.04 0 1 0-.002 2.078 1.04 1.04 0 0 0 .002-2.078zM35.88 33.07a1.04 1.04 0 1 0-.002 2.078 1.04 1.04 0 0 0 .002-2.078zM41.42 24.8h-5.07v-5.72c0-.4-.32-.72-.72-.72s-.72.32-.72.72v6.45c0 .4.32.72.72.72h5.79c.4 0 .72-.32.72-.72a.72.72 0 0 0-.72-.73z"/><path d="m39.19 15.46-3.75.13a.726.726 0 0 0 .05 1.45l3.73-.13c1.69 0 5.32 6.19 5.32 9.07v3.26h-1.87c-.4 0-.72.32-.72.72s.32.72.72.72h1.87v2.41c0 .62-.51 1.13-1.13 1.13-.4 0-.72.32-.72.72s.32.72.72.72a2.57 2.57 0 0 0 2.57-2.57v-7.11c0-3.3-3.86-10.52-6.79-10.52z"/>
    </SvgIcon>
  )
};

export const PaymentCardIcon = (params) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...params}>
      <path d="M11.72 27.89H9.98c-.67 0-1.21-.54-1.21-1.21v-9.79h25.06v.79c0 .38.31.69.69.69.38 0 .69-.31.69-.69V11.6c0-1.43-1.17-2.6-2.6-2.6H9.98c-1.43 0-2.6 1.17-2.6 2.6v15.08c0 1.43 1.17 2.6 2.6 2.6h1.74c.38 0 .69-.31.69-.69s-.3-.7-.69-.7zm-1.74-17.5h22.64c.67 0 1.21.54 1.21 1.21v1.15H8.77V11.6c0-.67.54-1.21 1.21-1.21z"/><path d="M22.95 24.09c0-1.28-1.04-2.31-2.31-2.31h-2.47c-1.28 0-2.31 1.04-2.31 2.31v1.06c0 1.28 1.04 2.31 2.31 2.31h2.47c1.28 0 2.31-1.04 2.31-2.31v-1.06zm-1.39 1.06c0 .51-.41.92-.92.92h-2.47c-.51 0-.92-.41-.92-.92v-1.06c0-.51.41-.92.92-.92h2.47c.51 0 .92.41.92.92v1.06zM36.33 31.85h-7.74c-.38 0-.69.31-.69.69 0 .38.31.69.69.69h7.74c.38 0 .69-.31.69-.69 0-.38-.31-.69-.69-.69zM36.33 34.19H23.01c-.38 0-.69.31-.69.69 0 .38.31.69.69.69h13.32c.38 0 .69-.31.69-.69 0-.38-.31-.69-.69-.69z"/><path d="M36.8 18.72H16.6c-2.1 0-3.82 1.71-3.82 3.82v12.65c0 2.1 1.71 3.82 3.82 3.82h20.2c2.1 0 3.82-1.71 3.82-3.82V22.53c0-2.1-1.71-3.81-3.82-3.81zm2.43 16.46c0 1.34-1.09 2.43-2.43 2.43H16.6c-1.34 0-2.43-1.09-2.43-2.43V22.53c0-1.34 1.09-2.43 2.43-2.43h20.2c1.34 0 2.43 1.09 2.43 2.43v12.65z"/>
    </SvgIcon>
  )
};

export const PaymentInvoiceIcon = (params) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...params}>
      <path d="M25.18 15.7h-7.52c-.38 0-.68-.3-.68-.68s.3-.68.68-.68h7.52c.38 0 .68.3.68.68s-.3.68-.68.68zM30.61 34.02h-5.37c-.38 0-.68-.3-.68-.68s.3-.68.68-.68h5.37a.68.68 0 0 1 0 1.36zM30.61 20.45H17.66c-.38 0-.68-.3-.68-.68s.3-.68.68-.68h12.95a.68.68 0 0 1 0 1.36zM30.78 23.45H17.83c-.38 0-.68-.3-.68-.68s.3-.68.68-.68h12.95c.38 0 .68.3.68.68s-.3.68-.68.68zM30.78 26.44H17.83c-.38 0-.68-.3-.68-.68s.3-.68.68-.68h12.95c.38 0 .68.3.68.68s-.3.68-.68.68zM30.78 31.02h-7.66c-.38 0-.68-.3-.68-.68s.3-.68.68-.68h7.66c.38 0 .68.3.68.68s-.3.68-.68.68z"/><path d="M31.86 39.12H16.14c-2.06 0-3.74-1.68-3.74-3.74V12.61c0-2.06 1.68-3.74 3.74-3.74h15.73c2.06 0 3.74 1.68 3.74 3.74v22.78a3.747 3.747 0 0 1-3.75 3.73zM16.14 10.24c-1.31 0-2.38 1.07-2.38 2.38V35.4c0 1.31 1.07 2.38 2.38 2.38h15.73c1.31 0 2.38-1.07 2.38-2.38V12.61c0-1.31-1.07-2.38-2.38-2.38H16.14z"/>
    </SvgIcon>
  )
};

export const DeleteIcon = (params) => {
  return (
    <SvgIcon sx = { (theme) => ({
      fill: theme.palette.primary.main
    })}>
      <path d="M7.3 20.5q-.75 0-1.275-.525Q5.5 19.45 5.5 18.7V6h-1V4.5H9v-.875h6V4.5h4.5V6h-1v12.7q0 .75-.525 1.275-.525.525-1.275.525ZM17 6H7v12.7q0 .125.088.213.087.087.212.087h9.4q.1 0 .2-.1t.1-.2ZM9.4 17h1.5V8H9.4Zm3.7 0h1.5V8h-1.5ZM7 6V19v-.3Z"/>
    </SvgIcon>
  )
};


