// import React, {useContext, useEffect, useRef} from 'react';
// import ModalUnstyled from '@mui/base/ModalUnstyled';

// import { appContext } from '../../contexts';
// import {SearchTransition} from './SearchTransition';

// export default function SearchModal() {
//   const {app} = useContext(appContext)

//   return (
//     <ModalUnstyled open={app.search} component='div'>
//       <SearchTransition in={app.search} />
//     </ModalUnstyled>
//   )
// };

import { appContext } from '../../contexts';
import { closeSearch } from '../../contexts/actions';

import React, {useContext, useEffect} from 'react';
import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import {SearchTransition} from './SearchTransition';
import SearchContent from './SearchContent';
import { isBrowser } from '../../utility';

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`;

export default function SearchModal() {
  const {app, setApp} = useContext(appContext)

  const handleClose = (e) => {
    e.preventDefault()
    setApp(closeSearch())
  }

  useEffect(() => {
    isBrowser && window.addEventListener('popstate', handleClose, false)
    return () =>  isBrowser && window.removeEventListener('popstate', handleClose)
  })

  return (
    <div>
      <Modal
        open={app.search}
        onClose={handleClose}
        closeAfterTransition
      >
        <SearchTransition in={app.search}>
          <SearchContent />
        </SearchTransition>
      </Modal>
    </div>
  );
}