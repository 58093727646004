import React from 'react';
import { Button } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

export default function FbBtn ({children, href, sx}) {
  return (
    <Button
      sx = { (theme) => ({
        fontSize: '0.75rem',
        color: 'inherit',
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '& svg': {
          fontSize: '2.5em !important'
        },
        '& svg path': {
          fill: theme.palette.primary.main
          // fill: theme.palette.white,
        },
        ...sx
      })}
      target={'__blank'}
      href={href}
      size='large'
      component={'a'} 
      startIcon={<FacebookOutlinedIcon/>}
      disableRipple={true}
    >{children}</Button>
  )
};