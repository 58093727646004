import React, {useContext, memo} from 'react';
import { navigate } from 'gatsby';

import { appContext } from '../../contexts';
import { closeSearch } from '../../contexts/actions';
import { Box } from '@mui/system';

import { Book } from './SearchBook';


export const SearchResults = memo(({dialogi, books}) => {

  const {setApp} = useContext(appContext)
  
  const onBookClick = (url) => {
    console.log(url)
    navigate(url)
    setTimeout(()=>setApp(closeSearch()), 300)
  }

  // Combine books and dialogi
  const items = []
  books.forEach(element => {
    items.push({
      ...element.attributes,
      type: 'book'
    })
  });
  dialogi.forEach(element => {
    items.push({
      ...element.attributes,
      type: 'dialogi'
    })
  });

  console.log(items)


  return (
      <Box sx = { (theme) => ({
        display: {
          md: 'grid'
        },
        gridTemplateColumns: {
          md: 'repeat(2, minmax(0, 1fr))',
          lg: 'repeat(3, minmax(0, 1fr))',
          // xxxl: 'repeat(4, minmax(0, 1fr))',
        },
        gap: {
          md: 4,
          lg: 8
        },
        width: {
          xl: '80%'
        },
        m: '0 auto',
        mt: {
          xs: 10,
          xl: '15vh'
        },
        pb: {
          xs: 14,
        }
      })}>
        {items.map((item) => {
          {/* console.log(attributes) */}
          return (
            <Book 
              onClick={()=> onBookClick( item.type === 'book' ? `/${item.category.data.attributes.slug}/${item.slug}` : `/dialogi/${item.leto.data.attributes.year}/${item.stevilka}`)}
              key={item.title} 
              image={item.cover.data.attributes.formats.medium} 
              title={item.title}
              subtitle={ item.type === 'book' ? item.subtitle :`Dialogi ${item.stevilka} ${item.leto.data.attributes.year}`}
              author={item.type === 'book' ? item.author : false}
            />
          )
        })}
      </Box>
  )
})
