import React, {useContext} from 'react';

import { appContext } from '../../contexts';
import { openSearch } from '../../contexts/actions';

import { SvgIcon, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const SearchSvg = styled(SvgIcon)(
  ({ theme }) => `
    width: 1em;
    height: 1em;
    font-size: 1em;
    & path, & circle {
      stroke: ${theme.palette.primary.main};
      stroke-width: 2px;
      stroke-linecap: round;
      fill: none;
    }
  `,
);
export const SearchIcon = (props) => (
  <SearchSvg {...props}>
    <path d="m18.5 18.4 3.7 3.7"/>
    <circle cx="9.6" cy="9.6" r="8.1"/>
  </SearchSvg>
)

export default function SearchBtn({sx}) {

  const {setApp} = useContext(appContext)

  return (
    <IconButton sx={sx} onClick={(e) => {
      e.preventDefault()
      setApp(openSearch())
    }}>
      <SearchIcon />
    </IconButton>
  )
};
