exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dialogi-index-js": () => import("./../../../src/pages/dialogi/index.js" /* webpackChunkName: "component---src-pages-dialogi-index-js" */),
  "component---src-pages-dialogi-navodila-za-avtorje-js": () => import("./../../../src/pages/dialogi/navodila-za-avtorje.js" /* webpackChunkName: "component---src-pages-dialogi-navodila-za-avtorje-js" */),
  "component---src-pages-dialogi-o-dialogih-js": () => import("./../../../src/pages/dialogi/o-dialogih.js" /* webpackChunkName: "component---src-pages-dialogi-o-dialogih-js" */),
  "component---src-pages-dialogi-postani-narocnik-js": () => import("./../../../src/pages/dialogi/postani-narocnik.js" /* webpackChunkName: "component---src-pages-dialogi-postani-narocnik-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kosarica-js": () => import("./../../../src/pages/kosarica.js" /* webpackChunkName: "component---src-pages-kosarica-js" */),
  "component---src-templates-article-single-js": () => import("./../../../src/templates/article-single.js" /* webpackChunkName: "component---src-templates-article-single-js" */),
  "component---src-templates-books-archive-js": () => import("./../../../src/templates/books-archive.js" /* webpackChunkName: "component---src-templates-books-archive-js" */),
  "component---src-templates-books-single-js": () => import("./../../../src/templates/books-single.js" /* webpackChunkName: "component---src-templates-books-single-js" */),
  "component---src-templates-dialogi-archive-js": () => import("./../../../src/templates/dialogi-archive.js" /* webpackChunkName: "component---src-templates-dialogi-archive-js" */),
  "component---src-templates-dialogi-single-js": () => import("./../../../src/templates/dialogi-single.js" /* webpackChunkName: "component---src-templates-dialogi-single-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

