import React from 'react';
import { Link } from "gatsby"
import { Box } from '@mui/system';
import logo from './../../images/logo.svg'

export default function Logo({siteTitle, style}) {
  return (
    <Link to='/' style={style}>
      <Box 
        component='img' 
        src={logo} 
        alt={`${siteTitle}`}
        sx={{
          p: {xs: 4, sm: 5},
          display: 'block',
          width: 'auto',
          height: theme => ({
            xs: theme.spacing(10),
            sm: theme.spacing(11)
          })
        }}
      />
    </Link>
  )
};
