import React from 'react';
import { Box } from '@mui/system';

export default function NagradaLabel({size = {
  xs: '7rem',
  xxl: '8rem'
}, text, type }) {
  return (
    <Box 
      sx = { (theme) => ({
        width: size,
        height: size,
        bgcolor: theme.palette.black,
        borderRadius: '50%',
        color: theme.palette.white,
        textAlign: 'center',
        fontSize: size,
        lineHeight: '1.25',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        bottom: '-1rem',
        zIndex: 10,
        transform: {
          xs: 'translateX(50%)',
          md2: 'translateX(25%)',
          lg: 'translateX(50%)',
        },
        padding: '0.2em',

        '& span': {
          display: 'block',
          fontSize: '0.6em'
        },

        '& em': {
          display: 'block',
          fontWeight: 'bold',
          fontStyle: 'normal'
        },
        '& p': {
          margin: 0,
          fontSize: '0.12em'
        }
    })}>
      <p><span>{type}</span><em>{text}</em></p>
    </Box>
  )
};
