import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { navigate } from 'gatsby';

export default function LangBtn({lang}) {

  return (
    <IconButton
      onClick={() => lang === 'sl' ? navigate('/en') : navigate(-1)}
    >
      <Box sx = { (theme) => ({
        width: '3rem',
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        color: theme.palette.black,
        padding: '1em'
      })}>
        {lang === 'sl' ? 'En' : 'Sl'}
      </Box>
    </IconButton>
  )
};
