import React, {useContext} from 'react';
import { navigate } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { setYear } from '../../contexts/actions';
import { appContext } from '../../contexts';

import { Box } from '@mui/system';

import './archiveNavigation.scss'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ArchiveNavigation({years, selectedYear}) {

  // console.log(selectedYear)
  
  const {app, setApp} = useContext(appContext)
  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))

  const initSlide = app.dialogiYear ? years.indexOf(app.dialogiYear) : years.indexOf(selectedYear)

  // console.log('State Year', app.dialogiYear)
  // console.log('Init index', initSlide)

  let slidePerView = 3

  if ( lg ) {
    slidePerView = 6
  } else if (xl) {
    slidePerView = 9
  }

  return (
    <Box sx = { (theme) => ({
      bgcolor: theme.palette.white,
    })}>
      <Box sx = { (theme) => ({
        width: {
          lg: '60%',
          // xl: '50%'
        },
        mx: 'auto',
        mt: {
          lg: theme.spacing(10)
        },
        mb: {
          lg: theme.spacing(8)
        }
      })}>
        <Swiper
          keyboard={true}
          slideToClickedSlide={true}
          spaceBetween={0}
          slidesPerView={slidePerView}
          centeredSlides={true}
          initialSlide={initSlide}
          onSlideChange={(e) => {
            // console.log('slider change')
            // console.log(e.activeIndex)
            setApp(setYear(years[e.activeIndex]))
            navigate(`/dialogi/${years[e.activeIndex]}/`)
          }}
        >
          {years.map((y, ind) => (
            <SwiperSlide key={y}>
              {y}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
   
  )
};
