import React, {useContext, useEffect, useRef} from 'react';
import * as styles from './../../styles/modal.module.scss'
import { Transition } from 'react-transition-group';
import { appContext } from '../../contexts';
import { closeMenu } from '../../contexts/actions';
import {easing as ease} from '../../utility/easings'

import { isBrowser } from '../../utility';

export default function MenuModal({children}) {

  const {app, setApp} = useContext(appContext)
  const path = useRef(null);
  const isMounted = useRef(false);

  const duration = 1000
  const numPoints = 2
  const delayPointsArray = [];
  const delayPointsMax = 0;

  let timeStart = Date.now();
  let isOpened = app.menu;
  let isAnimating = false;
  
  const closeOnBack = () => setApp(closeMenu());

  const onStartAnimation = () => {
    if (isOpened) {
      document.body.style.position = 'fixed'
      document.body.style.overflow = 'hidden'
    } 
    else {
      document.body.style.position = 'static'
      document.body.style.overflow = 'auto'
    }
  }

  const onEndAnimation = () => console.log('End Animation')

  const updatePath = (time) => {
    const points = [];
    for (let i = 0; i < numPoints; i++) {
      const thisEase = isOpened ? 
        (i === 1) ? ease.cubicOut : ease.cubicInOut:
        (i === 1) ? ease.cubicInOut : ease.cubicOut;
      points[i] = thisEase(Math.min(Math.max(time - delayPointsArray[i], 0) / duration, 1)) * 100
      //points[i] = thisEase(Math.min(time / this.duration, 1)) * 100
    }
    //console.log(isOpened, points)

    let str = '';
    str += (isOpened) ? `M 0 0 V ${points[0]} ` : `M 0 ${points[0]} `;
    for (let i = 0; i < numPoints - 1; i++) {
      const p = (i + 1) / (numPoints - 1) * 100;
      const cp = p - (1 / (numPoints - 1) * 100) / 2;
      str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${points[i + 1]} `;
    }
    str += (isOpened) ? `V 0 H 0` : `V 100 H 0`;

    return str;
  }

  const render = () => path?.current.setAttribute('d', updatePath(Date.now() - timeStart))
  
  const renderLoop = () => {
    render();
    if (Date.now() - timeStart < duration + delayPointsMax) {
      requestAnimationFrame(() => {
        renderLoop();
      });
    }
    else {
      isAnimating = false;
      onEndAnimation()
    }
  }

  useEffect(()=> {
    if (isMounted.current) {
      console.log('Component init ...')
      isAnimating = true
      for (var i = 0; i < numPoints; i++) {
        delayPointsArray[i] = 0
      }
      timeStart = Date.now()
      onStartAnimation()
      renderLoop()

      isBrowser && window.addEventListener('popstate', closeOnBack, false)
      
      return () => {
        document.body.style.position = 'static'
        document.body.style.overflow = 'auto'
        isBrowser && window.removeEventListener('popstate', closeOnBack)
      }
    } else {
      console.log('Component updating ...')
      isMounted.current = true;
    }
  }, [app.menu])

  return (
    <Transition in={app.menu} timeout={duration}>
    {state => (
      <div className={`${styles.container} ${state}-modal ${app.menu ? 'menu-opened' : 'menu-closed'}`} >
        <svg className={styles.overlay} viewBox="0 0 100 100" preserveAspectRatio="none">
          <path ref={path} className={styles.path}></path>
        </svg>
        <div className={styles.content} style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          {children}
        </div>
      </div>
    )}
    </Transition>
  )
};
