import React, {useContext} from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from '@mui/system';
import ContactLinks from "./ContactLinks";
import FbBtn from './FbBtn';
import { Container, GridContainer } from '../../grid';

import { appContext } from '../../contexts';



const SmallText = styled('small')(
  ({ theme }) => `
    font-size: 0.7rem;
    display: block;
    line-height: 1.35;

    & a {
      font-weight: bold
    }
`)




export const FooterSl = ({dialogiCurrentYear}) => {

  const {app} = useContext(appContext)
  
  const data = useStaticQuery(graphql`
    query getFooterData {
      categories: allStrapiBookCategory(filter: {locale: {eq: "sl"}}) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `)
  const booksCatagories = data.categories.edges.filter(({node}) => node.slug !== 'kamisibaji')
  
  return (
  <Container py={{xs: 7}}>
    <GridContainer>
      <Grid item xs={12} md={4}>
        <h2>Kontakt</h2>
        <p>Založba Aristej d.o.o.<br/>
          Marčičeva ulica 19<br/>
          2000 Maribor
        </p>
        <p>Poštni naslov:<br/>Založba Aristej, p.p. 115, 2116 Maribor</p>
        <ContactLinks mt={7} /> 
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Knjige</h2>
        <ul>
          {booksCatagories.map(({node}) => <li key={node.slug}><Link  className='underlined-link' to={`/${node.slug}`}>{node.name}</Link></li>)}
        </ul>
        <h2><Link className='underlined-link' to={'/kamisibaji'}>Kamišibaji</Link></h2>
        <h2>Dialogi</h2>
        <ul>
          <li>
            <Link className='underlined-link' to={'/dialogi/o-dialogih'}>O Dialogih</Link>
          </li>
          <li>
            <Link className='underlined-link' to={`/dialogi/${app.dialogiYear ? app.dialogiYear : dialogiCurrentYear}`}>Posamezne številke in arhiv</Link>
          </li>
          <li>
            <Link className='underlined-link' to={'/dialogi/navodila-za-avtorje'}>Navodila za avtorje</Link>
          </li>
          <li>
            <Link className='underlined-link' to={'/dialogi/postani-narocnik'}>Postani naročnik</Link>
          </li>
        </ul>
        
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Informacije</h2>
        <ul>
          <li>
            <Link className='underlined-link' to={'/o-zalozbi'}>O založbi</Link>
          </li>
          <li>
            <Link className='underlined-link' to={'/pravno-obvestilo'}>Pravno obvestilo</Link>
          </li>
          <li>
            <Link className='underlined-link' to={'/splosni-pogoji'}>Splošni  pogoji</Link>
          </li>
          <li>
            <Link className='underlined-link' to={'/varovanje-osebnih-podatkov'}>Varovanje osebnih podatkov</Link>
          </li>
        </ul>
        <Box mt={7} ml={-2}>
          <FbBtn href='https://www.facebook.com/zalozba.aristej/'>Založba Aristej</FbBtn>
          <FbBtn href='https://www.facebook.com/revijadialogi/'>Revija Dialogi</FbBtn>
        </Box>
        
        <Box sx = { (theme) => ({
          mt: 8,
          display: 'flex',
          gap: 4,
          alignItems: 'flex-end',
          '&>div': {
            flexShrink:0
          }
        })}>
          <StaticImage placeholder='none' alt="Logo Javne agencije za knjigo RS" width={100} src='./../../images/jak_logo.svg' />
          <SmallText>Prenovo spletne strani v letu 2022 je omogočila Javna agencija za knjigo RS.</SmallText>
        </Box>
        <Box sx = { (theme) => ({
          mt: 8,
          display: 'flex',
          gap: 3,
          alignItems: 'center',
          '&>div': {
            flexShrink:0
          }
        })}>
          <StaticImage placeholder='none' alt="Logo Primož Weingerl" width={35} src='./../../images/logo-pw.svg' />
          <SmallText>Oblikovanje in razvoj<br/> <a href="https://weingerl.com">weingerl.com</a> </SmallText>
        </Box>
      </Grid>
    </GridContainer>
    <Box mt={8}>
      <SmallText>© Založba Aristej {new Date().getFullYear()} | Vse pravice pridržane</SmallText>
    </Box>
  </Container>
  )
};
