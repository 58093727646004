
export const getAuthorSurname = (author) => {
  
  // if author is not set return ž
  if (!author) return 'ž'

  // split to array to get first author
  let firstAuthor = author.split(', ')[0]

  // remove parentheses
  firstAuthor = firstAuthor.split(' (')[0]

  // split again to get surname
  let surname = firstAuthor.split(' ').at(-1)
  
  return surname.toLowerCase()
}

export const isBrowser = typeof window !== "undefined"

export const isEmpty = value => {
  if (typeof value === 'string'){
    return !value.trim().length;
  } else {
    return !value
  }
}
