import React, { useEffect, useState, useContext, useRef } from "react"
import { Link, navigate } from "gatsby";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme, styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Container } from "../../grid";
import DIALOGI_LOGO from './../../images/dialogi-logo.svg'

import ArchiveNavigation from "./ArchiveNavigation";

import { appContext } from "../../contexts";

const DIALOGI_SUBPAGES = [
  {label: 'Posamezne številke in arhiv'},
  {label: 'O Dialogih', slug: 'o-dialogih'},
  {label: 'Navodila za avtorje', slug: 'navodila-za-avtorje'},
  {label: 'Postani naročnik', slug: 'postani-narocnik'},
]

const getInd = (pathname, years=false) => {
  // console.log(pathname)
  // console.log(years)
  // console.log(years && (years.findIndex(year => pathname === `/dialogi/${year}/`) !== -1))

  // Check if it's any of the dialogi subpages
  const ind = DIALOGI_SUBPAGES.findIndex(page => {
    if (page.slug){
      const re = RegExp(String.raw`${page.slug}`)
      return re.test(pathname)
    } else {
      return false
    }
  })

  if (ind === -1) {
     // check if it's any of archive pages
     const re = RegExp(String.raw`^/dialogi/\d{4}/?$`)
     return re.test(pathname) ? 0 : false
  } else {
    return ind
  }
}

const Nav = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    // height: '8px',
    // bottom: '35%',
    bottom: '20%'
  },
  '& .MuiTabs-indicatorSpan': {
    // width: `calc(100% - ${theme.spacing(4)} - ${theme.spacing(4)})`,
    // backgroundColor: theme.palette.grey3
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: theme.palette.black,
    borderRadius: '50%'
  },
}));

const Item = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    zIndex: 5,
    padding: theme.spacing(2),
    color: theme.palette.grey6,
    fontWeight: 400,

    '&.Mui-selected': {
      // color: '#fff',
    },
    '&.Mui-focusVisible': {
    },
  }),
);

export default function DialogiNavigation({pageContext, location, years, dialogiCurrentYear, page}) {

  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  console.log(pageContext)
  // console.log(years)
  //console.log(location.pathname)

  const stickyHeader = useRef()

  // show back button
  const showBack = (page === 'dialogi-single' && lg) || (page === 'article-single')

  // const observer = new IntersectionObserver( 
  //   ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
  //   { threshold: [1] }
  // );
  

  // Set index of active tab
  const [tabValue, setTabValue] = useState(getInd(location.pathname, years));

  // Set active archive year, last year is default
  const {app} = useContext(appContext)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(()=>{
    setTabValue(getInd(location.pathname, years))
    // observer.observe(stickyHeader.current);

  }, [location, years])



  
  return (
    <>
      {/*  DIALOGI NAVIGATION */}
      <Box ref={stickyHeader} sx = { (theme) => ({
        bgcolor: theme.palette.white,
        py: {
          xs: theme.spacing(2),
          md: theme.spacing(4)
        },
        mt: {
          // xs: theme.spacing(4),
          md: theme.spacing(4)
        },
        // position: {
        //   lg: 'sticky'
        // },
        // top:'-1px',
        // left:0,
        // zIndex: 20,
        // '&.is-pinned': {
        //   borderBottom: `solid 1px ${theme.palette.grey3}`
        // }
      })}>
        <Container 
          py={0} 

          sx = { (theme) => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: theme.spacing(4),
            position: 'relative',
            zIndex: 10

          })}
        >
          {/* {label && 
            <Link style={{marginRight: 'auto'}} to={`/dialogi/${label.year}/${label.stevilka}`}>
              <DialogiLabel 
                {...label} 
                sx = { (theme) => ({
                  m: 0,
                  mt: '-0.25rem',
                  fontSize: {
                    xs: '1.25rem',
                    lg: '1.5rem'
                  }
                })}
              />
            </Link>
          } */}
          {showBack && <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => (page === 'article-single') ? navigate(`/dialogi/${pageContext.data.dialogi.leto.year}/${pageContext.data.dialogi.stevilka}`) : navigate(`/dialogi/${pageContext.leto.year}`)}
            sx = { (theme) => ({
              mr: 'auto'
          })}>
            { (page === 'article-single') ? 'Nazaj na številko' : 'Vse številke'}
          </Button>}
          {lg && 
            <Nav
              value={tabValue}
              onChange={handleTabChange}
              aria-label="styled tabs example"
            >
              {DIALOGI_SUBPAGES.map((item, i) => (
                <Item 
                  key={item.label} 
                  label={item.label} 
                  component={Link}
                  to={ i === 0 ? `/dialogi/${app.dialogiYear ? app.dialogiYear : dialogiCurrentYear}` : `/dialogi/${item.slug}`}
                />
              ))}
            </Nav>
          } 
          <Box 
            sx = { (theme) => ({
              width: {
                xs: theme.spacing(13),
                md: theme.spacing(14)
              },
          })}>
            <Link to='/dialogi' style={{display: 'block'}}>
              <img style={{width: '100%', height: 'auto'}} src={DIALOGI_LOGO} alt={'Dialogi Logo'} />
            </Link> 
          </Box>
        </Container>
      </Box>
      
      {/* DIALOGI ARCHIVE MENU */}
      {(page === 'dialogi-archive') &&  
        <ArchiveNavigation 
          years={years}
          selectedYear={pageContext.year}
        />
      }
    </>
  );
};
