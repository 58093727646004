import React from 'react';
import Box from '@mui/system/Box';
import { FooterSl } from './FooterSl';
import { FooterEn } from './FooterEN';

export default function Footer({pageContext, dialogiCurrentYear}){

  return (
    <Box 
      component={'footer'} 
      sx={ (theme) => ({
        backgroundColor: theme.palette.grey3,
        // backgroundColor: theme.palette.black,
        // color: theme.palette.white,
        '& h2':{
          fontSize: '1rem',
          margin: '2rem 0 0.5rem',
          fontWeight: 500
        },
        '& li': {
          fontSize: '0.875rem',
          lineHeight: '150%'
        },
        '& p': {
          fontSize: '0.875rem',
          lineHeight: '175%'
        },
        '& ul': {
          listStyle: 'none',
          p:0,
          m:0
        },
        '& a': {
          paddingBottom:  '0 !important',
          position: 'relative',
          zIndex: 10
        },
        '& a::after': {
          backgroundColor: 'var(--grey5)',
          // zIndex: 1
        },
        '& .MuiGrid-item:first-of-type h2': {
          mt: {xs: 0, md: '2rem'}
        }
    })}>
      {pageContext.langKey === 'sl' ? <FooterSl dialogiCurrentYear={dialogiCurrentYear} /> : <FooterEn />}
    </Box>
  )
};
