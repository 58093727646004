import React, {useContext, useState, useCallback} from 'react';
import { debounce } from "lodash";

import axios from 'axios';

import { appContext } from '../../contexts';
import { closeSearch } from '../../contexts/actions';

import SearchField from './SearchField';
import { SearchResults } from './SearchResults';
import { Box } from '@mui/system';



const getQuery = (query) => `
  query Search {
    search(query: "${query}"){
      books {
        data{
          attributes{
            slug
            category{
                data {
                    attributes {
                        slug
                    }
                }
            }
            title
            author
            subtitle
            cover{
                data{
                    attributes{
                      formats
                    }
                }
            }
          }
        }
      }
      dialogis {
          data {
              attributes {
                  cover {
                      data{
                          attributes{
                              formats
                          }
                      }
                  }
                  title
                  stevilka
                  leto {
                      data{
                          attributes{
                              year
                          }
                      }
                  }
              }
          }
      }
    }
  }
`
export default function SearchContent() {
  const {setApp} = useContext(appContext)

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [dialogi, setDialogi] = useState([])
  const [books, setBooks] = useState([])

  const handleSearch = useCallback(debounce((input) => {
        axios({
          url: process.env.GATSBY_STRAPI_API_URL + '/graphql',
          method: 'post',
          headers: {
            'Authorization': `Bearer ${process.env.GATSBY_STRAPI_TOKEN}`
          },
          data: {
            query: getQuery(input)
          }
        }).then((result) => {
          setLoading(false)
          setDialogi(result.data.data.search.dialogis.data)
          setBooks(result.data.data.search.books.data)
          // console.log(result.data.data.search)
        }).catch((e) => {
          setLoading(false)
          console.error(e) 
        })
  }, 1000), []);

  /** Trigger when model is closed */
  const handleClose = () => {
    setApp(closeSearch())
    setValue('')
    setDialogi([])
    setBooks([])
  }

  /** Trigger while typing */
  const handleTyping = (input) => {
    // Set new typed value
    setValue(input)

    if (input.length > 2) {
      setLoading(true)
      handleSearch(input)
    } else {
      setDialogi([])
      setBooks([])
    }
  }

  let isResult = ((dialogi.length !== 0 || books.length !== 0) && value.length>2)

  return (
    <Box sx = { (theme) => ({
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      transition: 'padding 300ms',
      p: {
        xs: 4,
        sm2: 8,
        md: 10,
        lg: 12
      },
      pt: {
        md: isResult ? 10 : '40vh',
        lg: isResult ? 12 : '40vh'
      }
    })}>
      {/* {lg && <CloseBtn 
        sx={{
          fontSize:'2rem',
          // padding:0,
          position: 'fixed',
          top: theme.spacing(6),
          right: theme.spacing(6)
        }} 
        onClick={handleClose} 
      />} */}

        <SearchField
          onChange={(input)=>handleTyping(input)}
          onClose={true && handleClose}
          loading={loading}
          value={value}
          sx = { (theme) => ({
            fontSize: {xs: '1.75rem', lg: '2rem', xxxl: '2.5rem'},
            position: 'relative',
            width: '100%',
            maxWidth: '800px',
            m: '0 auto',
            
          })}
        />
        { (isResult ) && 
        <SearchResults dialogi={dialogi} books={books} />
        }
    </Box>
  );
}