import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { Cover } from '../books/Cover';

export const Book = ({image, title, subtitle, author, onClick}) => {
  return (
    <Box onClick={onClick} sx = { (theme) => ({
      display: 'flex', 
      gap: theme.spacing(4),
      alignItems: 'center',
      mb: {xs: 2, lg: 6}, mt: {xs: 6, lg: 0},
      cursor: 'pointer',
    })}>
      <Box sx = { (theme) => ({
        width: {
          xs: theme.spacing(11),
          lg: theme.spacing(12),
          // xxl: theme.spacing(13)
        },
        flexShrink: 0,
      })}>
        <Cover className={'shadow-s'} external={true} img={{
          src: `${process.env.GATSBY_STRAPI_API_URL}${image.url}`,
          width: image.width,
          height: image.height
        }} />
      </Box>
      <Box>
        {author && <Typography variant="bookArchiveAuthor">{author}</Typography>}
        <Typography variant="bookArchiveTitle">{title}</Typography>
        <Typography variant="bookArchiveSubtitle">{subtitle}</Typography>

      </Box>
    </Box>
  )
}